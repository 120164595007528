html,
body,
#root {
  margin: 0;
  font-size: 16px;
  font-weight: 100;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #212F49;
  line-height: 24px;
  position: relative;
  z-index: 10001;
  overflow: hidden;
  height: 100%;
}

a {
  font-size: 14px;
  color: #00a0ff;
}

@media (min-width: 961px) {
  a:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
  font-family: "Averta", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
}

h1, h2 {
  font-weight: 300;
}

h1 strong, h2 strong {
  color: #FF5253;
}

h1 {
  font-size: 44px;
  line-height: 48px;
  color: #212f49;
}

h2 {
  font-size: 28px;
  line-height: 42px;
  color: #212F49;
}

small {
  line-height: 12px;
  font-size: 12px;
}

p {
  color: #737373;
  margin-bottom: 0;
  font-weight: 300;
}
